
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getCVEControlList, getVulnerabilityFilterData } from './security.service';
import { Pagination } from '../common';
import { showError, Progressing, EmptyState } from '@devtron-labs/devtron-fe-common-lib'
import { ViewType } from '../../config';
import { ReactComponent as Close } from '../../assets/icons/ic-error.svg'
import { ReactComponent as Search } from '../../assets/icons/ic-search.svg';
import ReactSelect from 'react-select';
import { styles, DropdownIndicator, ValueContainer, Option } from './security.util';
import { ReactSelectOptionType, VulnerabilityExposureState } from './security.types';
import NoResults from '../../assets/img/empty-noresult@2x.png';

export class VulnerabilityExposure extends Component<RouteComponentProps<{}>, VulnerabilityExposureState> {

    constructor(props) {
        super(props);
        this.state = {
            view: ViewType.FORM,
            searchApplied: false,
            searchObjectValue: "",
            form: {
                cve: "",
            },
            filters: {
                clusters: [],
                environments: [],
            },
            filtersApplied: {
                clusters: [],
                environments: [],
            },
            offset: 0,
            pageSize: 20,
            size: 0,
            cve: "",
            scanList: [],
        }
        this.searchCVE = this.searchCVE.bind(this);
        this.handleCVEChange = this.handleCVEChange.bind(this);
        this.clearCVE = this.clearCVE.bind(this);
        this.changePage = this.changePage.bind(this);
        this.changePagesize = this.changePagesize.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.search = this.search.bind(this);
        this.removeAllFilters = this.removeAllFilters.bind(this);
        this.removeFiltersAndSearch = this.removeFiltersAndSearch.bind(this);
    }

    componentDidMount() {
        getVulnerabilityFilterData().then((response) => {
            this.setState({
                filters: response.filters,
                view: ViewType.FORM,
            });
        }).catch((error) => {
            this.setState({ view: ViewType.ERROR });
        })
        if (this.props.location.search) {
            this.callGetCVEControlList();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            if (this.props.location.search) {
                this.callGetCVEControlList();
            }
            else {
                this.setState({
                    form: {
                        cve: "",
                    },
                    cve: "",
                    searchApplied: false,
                    filtersApplied: {
                        clusters: [],
                        environments: [],
                    },
                    offset: 0,
                    pageSize: 20,
                    size: 0,
                    view: ViewType.FORM,
                    scanList: [],
                })
            }
        }
    }

    callGetCVEControlList() {
        this.setState({ view: ViewType.LOADING });
        let payload = this.createPaylod();
        getCVEControlList(payload).then((response) => {
            this.setState({
                ...response.result,
                cve: payload.cveName,
                form: {
                    cve: payload.cveName,
                },
                view: ViewType.FORM,
            });
        }).catch((error) => {
            showError(error);
            this.setState({ view: ViewType.ERROR });
        })
    }

    createPaylod() {
        let searchStr = new URLSearchParams(this.props.location.search);
        let offset = searchStr.get('offset');
        let pageSize = searchStr.get('size');
        let payload = {
            offset: Number(offset) || 0,
            size: Number(pageSize) || 20,
            cveName: searchStr.get('cveName')?.toUpperCase(),
            appName: this.state.searchObjectValue,
            clusterIds: this.state.filtersApplied.clusters.map((s) => s.value),
            envIds: this.state.filtersApplied.environments.map((s) => s.value),
        }
        return payload;
    }

    handleSearchChange(event) {
        this.setState({ searchObjectValue: event.target.value });
    }

    handleFilterChange(filterType, selections: any) {
        let filtersApplied = {
            ...this.state.filtersApplied,
            [filterType]: selections || []
        }
        this.setState({ filtersApplied, view: ViewType.LOADING }, () => {
            this.callGetCVEControlList();
        })
    }

    handleFilterRemove(filterType: string, deletion: ReactSelectOptionType) {
        let filters = this.state.filtersApplied[filterType].filter((item) => item.value !== deletion.value);
        let filtersApplied = {
            ...this.state.filtersApplied,
            [filterType]: filters,
        }
        this.setState({
            filtersApplied,
            view: ViewType.LOADING
        }, () => {
            this.callGetCVEControlList();
        })
    }

    removeFiltersAndSearch() {
        this.setState({
            filtersApplied: {
                clusters: [],
                environments: [],
            },
            searchApplied: false,
            searchObjectValue: "",
            view: ViewType.LOADING
        }, () => {
            this.callGetCVEControlList();
        })
    }

    removeAllFilters() {
        this.setState({
            filtersApplied: {
                clusters: [],
                environments: [],
            },
            view: ViewType.LOADING
        }, () => {
            this.callGetCVEControlList();
        })
    }

    handleCVEChange(event): void {
        this.setState({
            form: {
                cve: event.target.value
            }
        })
    }

    search() {
        this.setState({ searchApplied: true }, () => {
            this.callGetCVEControlList();
        });
    }

    clearCVE(): void {
        let newUrl = `/security/policies/vulnerability`;
        this.props.history.push(newUrl);
    }

    searchCVE(event): void {
        event.preventDefault();
        let params = new URLSearchParams(this.props.location.search);
        const offset = params.get('offset') || 0;
        const pageSize = params.get('size') || 20;
        const cveName = this.state.form.cve;
        let newUrl = `/security/policies/vulnerability?cveName=${cveName}&offset=${offset}&size=${pageSize}`;
        this.props.history.push(newUrl);
    }

    changePage(pageNumber: number): void {
        let params = new URLSearchParams(this.props.location.search);
        const offset = (pageNumber - 1) * this.state.pageSize;
        const size = params.get('size');
        const cveName = params.get('cveName');
        let newUrl = `/security/policies/vulnerability?cveName=${cveName}&offset=${offset}&size=${size}`;
        this.props.history.push(newUrl);
    }

    changePagesize(pageSize: number): void {
        let params = new URLSearchParams(this.props.location.search);
        const offset = params.get('offset') || 0;
        const cveName = params.get('cveName');
        let newUrl = `/security/policies/vulnerability?cveName=${cveName}&offset=${offset}&size=${pageSize}`;
        this.props.history.push(newUrl);
    }

    renderSavedFilters() {
        let filters = ['clusters', 'environments'];
        let count = 0;
        return <div className="flex left flex-1 pt-10 pb-10 pl-18 pr-18">
            {filters.map((filter) => {
                return <>
                    {this.state.filtersApplied[filter].map((cluster) => {
                        count++;
                        return <div key={cluster.value} className="saved-filter">{cluster.label}
                            <button type="button" className="saved-filter__clear-btn pt-4 pb-4"
                                onClick={(event) => { this.handleFilterRemove(filter, cluster) }} >
                                <Close className="icon-dim-18 icon-n4 dc__vertical-align-middle" />
                            </button>
                        </div>
                    })}
                </>
            })}
            {count > 0 ? <button type="button" className="saved-filters__clear-btn"
                onClick={() => { this.removeAllFilters() }}>Clear All Filters
          </button> : null}
        </div>
    }

    renderFilters() {
        if (this.state.size > 0 || (this.state.size <= 0 && (this.state.searchApplied || this.state.filtersApplied.clusters.length || this.state.filtersApplied.environments.length))) {
            let filterTypes = ['clusters', 'environments'];
            return <div className='security-scan__filters'>
                <form onSubmit={(e) => { e.preventDefault(); this.search(); }} className="flex-1 flex mr-24">
                    <div className="dc__search-with-dropdown">
                        <Search className="icon-dim-20 ml-7" />
                        <input autoComplete="off" type="text" className="search-with-dropdown__search"
                            tabIndex={1}
                            placeholder={`Search applcations`}
                            value={this.state.searchObjectValue}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.search();
                                }
                            }} onChange={this.handleSearchChange} />
                        {this.state.searchApplied ? <Close className="icon-dim-20 icon-n4 mr-5" onClick={() => {
                            this.setState({ searchObjectValue: "" }, () => {
                                this.callGetCVEControlList();
                            })
                        }} /> : null}
                    </div>
                </form>
                <div className="flexbox">
                    {filterTypes.map((filter, index) => {
                        return <ReactSelect key={filter}
                            className={`dc__security-scan__filter security-scan__filter--${filter}`}
                            name={filter}
                            tabIndex={index + 2}
                            isMulti={true}
                            isClearable={false}
                            value={this.state.filtersApplied[filter]}
                            options={this.state.filters[filter]}
                            placeholder={`${filter}`}
                            hideSelectedOptions={false}
                            onChange={(selected) => this.handleFilterChange(filter, selected)}
                            components={{
                                DropdownIndicator,
                                ValueContainer,
                                Option: Option,
                            }}
                            styles={{
                                container: (base, state) => {
                                    return ({
                                        ...base,
                                        height: '36px',
                                    })
                                },
                                control: (base, state) => ({
                                    ...base,
                                    minHeight: '36px',
                                }),
                                ...styles,
                            }} />
                    })}
                </div>
            </div>
        }
    }

    renderList() {
        if (this.state.view === ViewType.LOADING) {
            return <div style={{ height: "calc(100vh - 280px)" }}>
                <Progressing pageLoader />
            </div>
        }
        else if ((this.state.size === 0) && (this.state.searchApplied || this.state.filtersApplied.clusters.length || this.state.filtersApplied.environments.length)) {
            return <div style={{ height: 'calc(100vh - 200px)' }}>
                <EmptyState >
                    <EmptyState.Image><img src={NoResults} alt="" /></EmptyState.Image>
                    <EmptyState.Title><h4>No Matching Results</h4></EmptyState.Title>
                    <EmptyState.Subtitle>No results found for the applied filters.</EmptyState.Subtitle>
                    <EmptyState.Button>
                        <button type="button" className="cta ghosted" onClick={this.removeFiltersAndSearch}>Clear all Filters</button>
                    </EmptyState.Button>
                </EmptyState>
            </div>
        }
        else if (this.state.view === ViewType.FORM && this.state.scanList.length) {
            return <>
                {this.renderFilters()}
                {this.renderSavedFilters()}
                <div className="vulnerability-exp-table">
                    <table className="w-100">
                        <tr className="vulnerability-exp-table__header">
                            <th className="vulnerability-exp-table__title">NAME</th>
                            <th className="vulnerability-exp-table__title">ENVIRONMENT</th>
                            <th className="vulnerability-exp-table__title"> POLICY</th>
                        </tr>
                        {this.state.scanList.map((cve) => {
                            return <tr key={cve.appName} className="vulnerability-exp-table__row">
                                <td className="vulnerability-exp-table__data">{cve.appName}</td>
                                <td className="vulnerability-exp-table__data">{cve.envName}</td>
                                <td className="vulnerability-exp-table__data">
                                    <div className={`security-tab__cell-policy--${cve.policy.toLowerCase()}`} >
                                        {cve.policy.toUpperCase()}
                                    </div>
                                </td>
                            </tr>
                        })}
                    </table>
                </div>
                {this.renderPagination()}
            </>
        }
    }

    renderPagination() {
        if (this.state.size > 20) {
            return <Pagination size={this.state.size}
                pageSize={this.state.pageSize}
                offset={this.state.offset}
                changePage={this.changePage}
                changePageSize={this.changePagesize} />
        }
    }

    render() {
        return <div className="vulnerability-exp">
            <div className="ml-24 mr-24 p-1">
                <h1 className="form__title form__title--mt-20">CVE Policy</h1>
                <p className="form__subtitle form__subtitle--m-0">Check the exposure of your system for a CVE-ID for future deployments.</p>
            </div>
            <form className="vulnerability-exp__form  ml-24 mr-16" onSubmit={this.searchCVE}>
                <div className="flexbox">
                    <span className="dc__position-rel">
                        <input autoComplete="off" type="text" className="form__input form__input--cve-search mr-16" autoFocus
                            placeholder="Enter CVE ID" value={this.state.form.cve} onChange={this.handleCVEChange} />
                        {this.state.form.cve && this.state.form.cve === this.state.cve ? <Close className="icon-dim-16 vulnerability-exp__clear-cve" onClick={this.clearCVE} /> : null}
                    </span>
                    <button className="cta">Search</button>
                </div>
                {this.state.cve ? <p className="mt-16 mr-24 mb-0">Showing results for <a href={`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${this.state.form.cve}`} rel="noopener noreferrer" target="_blank"> {this.state.cve}</a> </p> : null}
            </form>
            <hr className="mt-0 mb-0" />
            {this.renderList()}
        </div>
    }
}
